import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ContactInfo } from '../models/contact';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  defaultConectionV1,
  promotorApi,
  usersApi,
  walletPromotorApi,
} from '../constants/inclub-ribera-api.constant';
import { EventContactInfo } from '../models/event-contact';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  URL_API = `${defaultConectionV1}`;
  URL_API_USERS = `${usersApi}`;
  URL_API_PROMOTERS = `${promotorApi}`;
  URL_API_WALLET = `${walletPromotorApi}`;
  private refreshNeeded$ = new Subject<void>();
  private storage: Storage | undefined;
  private selectedOptionSubject = new Subject<string>();
  selectedOption$ = this.selectedOptionSubject.asObservable();
  private tokenSubject!: BehaviorSubject<string | null>;
  protected storageUser = sessionStorage;
  private userSubject!: BehaviorSubject<any>;
  public user$!: Observable<any>;

  constructor(private http: HttpClient) {
    this.storage = this.getStorage();
    this.userSubject = new BehaviorSubject<any>(null);

    this.user$ = this.userSubject.asObservable();
  }

  updateSelectedOption(option: string) {
    this.selectedOptionSubject.next(option);
  }

  get refreshNeeded() {
    return this.refreshNeeded$.asObservable();
  }

  requestRefresh() {
    this.refreshNeeded$.next();
  }

  private getStorage(): Storage | undefined {
    try {
      if (typeof window !== 'undefined' && window.sessionStorage) {
        return window.sessionStorage;
      } else {
        return undefined;
      }
    } catch (e) {
      console.warn('sessionStorage is not available:', e);
      return undefined;
    }
  }

  private getHeadersClient(): HttpHeaders {
    const token = localStorage.getItem('token') || '';
    return new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });
  }
  private getHeaders(): HttpHeaders {
    const token = this.storage?.getItem('token');
    return new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });
  }
  private getHeadersPromoters(): HttpHeaders {
    const token = this.storage?.getItem('tokenP');
    return new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    });
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
  getKey<T = any>(key: string): string | null | T {
    if (this.storage?.getItem(key)) {
      try {
        return JSON.parse(this.storage.getItem(key) as string) as T;
      } catch (e) {
        return this.storage.getItem(key);
      }
    } else {
      return null;
    }
  }

  set(key: string, data: any, parse = true): void {
    this.storageUser.setItem(key, parse ? JSON.stringify(data) : data);
    if (key === 'token') {
      this.tokenSubject.next(data);
    }
  }

  getToken(): string | null {
    return this.storage?.getItem('token') || null;
  }

  // with header

  get(module: string) {
    const url = `${this.URL_API}/${module}`;
    const headers = this.getHeaders();
    return this.http.get(url, { headers }).pipe(catchError(this.handleError));
  }

  getState(module: string, params?: { [key: string]: any }, userId?: number) {
    const url = new URL(`${this.URL_API}/${module}`);

    // Añadir parámetros a la URL si existen
    if (params) {
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );
    }

    const headers = this.getHeaders();
    return this.http
      .get(url.toString(), { headers })
      .pipe(catchError(this.handleError));
  }
  getStateByPromoter(
    module: string,
    params?: { [key: string]: any },
    userId?: number
  ) {
    const url = new URL(`${this.URL_API}/${module}`);

    // Añadir parámetros a la URL si existen
    if (params) {
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );
    }

    const headers = this.getHeadersPromoters();
    return this.http
      .get(url.toString(), { headers })
      .pipe(catchError(this.handleError));
  }

  getBookingByUserId(
    module: string,
    params?: { [key: string]: any },
    userId?: any
  ) {
    let url = `${this.URL_API}/${module}`;
    if (userId) {
      url += `/user/${userId}`;
    }
    const fullUrl = new URL(url);
    if (params) {
      Object.keys(params).forEach((key) =>
        fullUrl.searchParams.append(key, params[key])
      );
    }

    const headers = this.getHeaders();
    return this.http
      .get(fullUrl.toString(), { headers })
      .pipe(catchError(this.handleError));
  }

  getApiUsers(module: string) {
    const url = `${this.URL_API_USERS}/${module}`;
    const headers = this.getHeadersClient()
    return this.http.get(url, { headers }).pipe(catchError(this.handleError));
  }
  getApiUsersClient(module: string) {
    const url = `${this.URL_API_USERS}/${module}`;
    const headers = this.getHeadersClient();
    return this.http.get(url, { headers }).pipe(catchError(this.handleError));
  }
  getApiUsersAll() {
    const url = `${this.URL_API_USERS}`;
    return this.http.get(url).pipe(catchError(this.handleError));
  }
  getApiPromoters(module: string) {
    const url = `${this.URL_API_PROMOTERS}/${module}`;
    const headers = this.getHeadersPromoters();
    return this.http.get(url, { headers }).pipe(catchError(this.handleError));
  }

  getApiWalletMovementsById(module: string, params: any) {
    const url = `${this.URL_API_WALLET}/${module}`;
    const headers = this.getHeaders();
    const options = {
      headers,
      params,
    };

    return this.http.get(url, options).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  postPaymentWallet(module: string, params: any) {
    const url = `${this.URL_API_WALLET}/${module}`;
    const headers = this.getHeaders();
    return this.http.post(url, params, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }
  
  
  // postDataWalletPromotor(module: string, params: any) {
  //   const url = `${this.URL_API}/${module}`;
  //   const headers = this.getHeaders();
  //   return this.http.post(url, params, { headers }).pipe(
  //     map((resp: any) => resp),
  //     catchError(this.handleError)
  //   );
  // }

  getApiCardnumberById(id: number) {
    const url = `${this.URL_API_WALLET}/cardnumber/${id}`;
    const headers = this.getHeaders();
    return this.http.get(url, { headers }).pipe(catchError(this.handleError));
  }

  getApiBalanceById(id: number) {
    const url = `${this.URL_API_WALLET}/balance/${id}`;
    const headers = this.getHeaders();
    return this.http.get(url, { headers }).pipe(catchError(this.handleError));
  }

  getApiUserById(id: number) {
    const url = `${this.URL_API_USERS}/${id}`;
    const headers = this.getHeaders();

    return this.http.get(url, { headers }).pipe(catchError(this.handleError));
  }

  getApiUsersByPromotorId(id: number) {
    const url = `${this.URL_API_USERS}/promotor/${id}`;
    const headers = this.getHeaders();

    return this.http.get(url, { headers }).pipe(catchError(this.handleError));
  }

  getCalendarDate(roomOfferId: number) {
    const url = `${this.URL_API}/booking/calendarDate?roomOfferId=${roomOfferId}`;
    const headers = this.getHeaders();
    return this.http.get(url, { headers }).pipe(catchError(this.handleError));
  }

  postDataWalletPromotor(module: string, params: any) {
    const url = `${this.URL_API_WALLET}/${module}`;
    const headers = this.getHeaders();
    return this.http.post(url, params, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  postDataBooking(module: string, params: any) {
    const url = `${this.URL_API}/${module}`;
    const headers = this.getHeaders();
    return this.http.post(url, params, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }
  postDataBookingByPromoter(module: string, params: any) {
    const url = `${this.URL_API}/${module}`;
    const headers = this.getHeadersPromoters();
    return this.http.post(url, params, { headers }).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }
  getHistoricalFilter(filters: {
    bookingStateId: number;
    page: number;
    size: number;
    roomTypeId?: number;
    capacity?: number;
    offertimeInit?: string;
    offertimeEnd?: string;
  }) {
    // Construir la URL dinámica
    let queryParams = `?bookingStateId=${filters.bookingStateId}&page=${filters.page}&size=${filters.size}`;

    // Verifica y añade roomTypeId si está presente
    if (filters.roomTypeId !== undefined) {
      queryParams += `&roomTypeId=${filters.roomTypeId}`;
    }

    // Verifica y añade capacity si está presente
    if (filters.capacity !== undefined) {
      queryParams += `&capacity=${filters.capacity}`;
    }

    // Verifica y añade offertimeInit y offertimeEnd si están presentes
    if (filters.offertimeInit) {
      queryParams += `&offertimeInit=${filters.offertimeInit}`;
    }
    if (filters.offertimeEnd) {
      queryParams += `&offertimeEnd=${filters.offertimeEnd}`;
    }

    // Realizar la solicitud GET con la URL completa
    const fullUrl = `${this.URL_API}${queryParams}`;
    const headers = this.getHeaders();
    return this.http.get(fullUrl, { headers });
  }

  // without header

  getData(module: string, id?: number, path?: string) {
    let url = `${this.URL_API}/${module}`;
    if (id !== undefined) {
      url += `/${id}`;
    }
    if (path) {
      url += `/${path}`;
    }
    return this.http.get(url).pipe(catchError(this.handleError));
  }

  postData(module: any, params: any) {
    const url = `${this.URL_API}/${module}`;
    return this.http.post(url, params).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  deleteData(module: string, params?: any) {
    const url = `${this.URL_API}/${module}`;
    return this.http.delete(url, params).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  deleteById(module: string, id: any) {
    const url = `${this.URL_API}/${module}/${id}`;
    return this.http.delete(url).pipe(
      map((resp: any) => resp),
      catchError(this.handleError)
    );
  }

  sendContactInfo(data: ContactInfo): Observable<any> {
    return this.http.post<any>(`${this.URL_API}/users/contact-info`, data);
  }

  sendEventContactInfo(data: EventContactInfo): Observable<any> {
    return this.http.post<any>(
      `${this.URL_API}/users/event-contact-info`,
      data
    );
  }

  deleteBooking(id: number) {
    return this.http.delete(`${this.URL_API}/booking/${id}`);
  }
}
